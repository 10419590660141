import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import arrow from "../../images/application-development/arrow.svg"
import react1 from "../../images/application-development/react1.png"
import cir from "../../images/custom-web-application/cir.svg"
import Frame from "../../images/mobile-app-developmemt/Frame.svg"
import dots from "../../images/mobile-app-developmemt/Group.svg"
import * as styles from "./FieldProven.module.scss"

const FieldProven = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <div className={styles.cir}>
        <img loading="lazy" decoding="async" src={cir} alt="circle" />
      </div>
      <div className={styles.dots1}>
        <img loading="lazy" decoding="async" src={dots} alt="dots" />
      </div>
      <div className={styles.react1}>
        <img loading="lazy" decoding="async" src={react1} alt="react" />
      </div>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col xl={4} lg={6} xs={12} key={i} className="gap-30">
              <div className={styles.card}>
                {e?.buttons[0]?.url && (
                  <Link to={e?.buttons[0]?.url}>
                    <img
                      className={styles.arrowImg}
                      src={arrow}
                      alt="url_arrow"
                      loading="lazy"
                      decoding="async"
                    />
                  </Link>
                )}
                <img
                  src={e?.image1[0]?.localFile?.publicURL}
                  loading="lazy"
                  decoding="async"
                  alt={e?.title}
                />
                <h3>{e?.title}</h3>
                <p>{e?.subTitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <div className={styles.dots3}>
        <img src={Frame} alt="frame-icon" loading="lazy" decoding="async" />
      </div>
      <div className={styles.dots4}>
        <img loading="lazy" decoding="async" src={dots} alt="dots-bg" />
      </div>
    </div>
  )
}

export default FieldProven
