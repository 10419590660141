// extracted by mini-css-extract-plugin
export var boxContent = "MeetInvozone-module--boxContent--b42d9";
export var bulitLine = "MeetInvozone-module--bulitLine--fc4af";
export var cards = "MeetInvozone-module--cards--307ed";
export var cardsContent = "MeetInvozone-module--cardsContent--6b32a";
export var cardsZ = "MeetInvozone-module--cardsZ--da567";
export var description = "MeetInvozone-module--description--5e463";
export var devepDots1 = "MeetInvozone-module--devepDots1--b324c";
export var devepSer1 = "MeetInvozone-module--devepSer1--9340f";
export var heading = "MeetInvozone-module--heading--0797e";
export var tabData = "MeetInvozone-module--tabData--c049a";
export var tabbingCards = "MeetInvozone-module--tabbingCards--49794";