// extracted by mini-css-extract-plugin
export var accImg = "MeetInvozone2-module--accImg--c1347";
export var approachImg = "MeetInvozone2-module--approachImg--4e2a0";
export var approchDot1 = "MeetInvozone2-module--approchDot1--b7361";
export var card = "MeetInvozone2-module--card--5524d";
export var cir = "MeetInvozone2-module--cir--1eb98";
export var description = "MeetInvozone2-module--description--d09ce";
export var dots1 = "MeetInvozone2-module--dots1--4f2f6";
export var dots2 = "MeetInvozone2-module--dots2--1492e";
export var dots3 = "MeetInvozone2-module--dots3--3225d";
export var head = "MeetInvozone2-module--head--0d6f9";
export var heading = "MeetInvozone2-module--heading--979a3";
export var meetInvozone2 = "MeetInvozone2-module--meetInvozone2--84ed6";
export var quesColor = "MeetInvozone2-module--quesColor--56001";
export var quesColorSelected = "MeetInvozone2-module--quesColorSelected--49c32";
export var textColor = "MeetInvozone2-module--textColor--7d875";