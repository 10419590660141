import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import number1 from "../../images/application-development/20.svg"
import number4 from "../../images/application-development/300.svg"
import number2 from "../../images/application-development/4.svg"
import number3 from "../../images/application-development/5.svg"
import number from "../../images/application-development/number1.svg"
import ViewableCountUp from "../../utils/ViewableCountUp"
import * as styles from "./InvozoneNumber.module.scss"

const cardsData = [
  {
    heading: `InvoZone
    In Numbers`,
  },
  {
    image: number,
    title: 500,
    sign: `+`,
    description: `Developers`,
  },
  {
    image: number4,
    title: 300,
    sign: `+`,
    description: `Projects Completed`,
  },
  {
    image: number1,
    title: 20,
    sign: `+`,
    description: `Awards & Achievements`,
  },
  {
    image: number2,
    title: 4,
    sign: ``,
    description: `Global Offices`,
  },
  {
    image: number3,
    title: 5,
    sign: ``,
    description: `Stars Clutch Review`,
  },
]

const InvozoneNumber = () => {
  return (
    <div className={styles.number}>
      <Container>
        <div className={styles.cardsData}>
          <Row className={`${styles.center} gap-30`}>
            {cardsData?.map(
              (item, index) =>
                index < 1 && (
                  <Col lg={4} className="gap-30" key={index}>
                    <div className={styles.card}>
                      <h2>{item?.heading}</h2>
                    </div>
                  </Col>
                )
            )}
            {cardsData?.map(
              (item, index) =>
                index > 0 && (
                  <Col lg={4} md={6} className="gap-30" key={index}>
                    <div className={styles.cards}>
                      <img
                        src={item?.image}
                        alt={item?.description}
                        loading="lazy"
                        decoding="async"
                      />
                      <ViewableCountUp
                        start={0}
                        end={item?.title}
                        duration={5}
                        suffix={item?.sign}
                      />
                      <p>{item?.description}</p>
                    </div>
                  </Col>
                )
            )}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default InvozoneNumber
