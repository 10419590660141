import { graphql } from "gatsby"
import React from "react"
import Banner from "../components/application-development/Banner"
import FieldProven from "../components/application-development/FieldProven"
import GotProject from "../components/application-development/GotProject"
import InvozoneNumber from "../components/application-development/InvozoneNumber"
import MeetInvozone from "../components/application-development/MeetInvozone"
import MeetInvozone2 from "../components/application-development/MeetInvozone2"
import OurApproach from "../components/application-development/OurApproach"
import Outsource from "../components/application-development/Outsource"
import Providing from "../components/application-development/Providing"
import Providing2 from "../components/application-development/Providing2"
import RefineProduct from "../components/application-development/RefineProduct"
import Testimonials from "../components/application-development/Testimonials"
import AngularTechStacks from "../components/application-development/AngularTechStacks"
import AngularTechStacksMobile from "../components/application-development/AngularTechStacksMobile"
import WorkPortfolio from "../components/common/WorkPortfolio"
import ContactSales from "../components/common/ContactSales"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/application_development_services_ffe19566ca.webp"
    />
  )
}
const applicationDevelopment = ({ data }) => {
  const heroSection = data?.strapiPage?.sections[0]
  const fieldProven = data?.strapiPage?.sections[1]
  const outsource = data?.strapiPage?.sections[2]
  const provi = [data?.strapiPage?.sections[3], data?.strapiPage?.sections[4]]
  const approach = data?.strapiPage?.sections[5]
  const meetInvozone = data?.strapiPage?.sections[6]
  const refineProduct = data?.strapiPage?.sections[7]
  const exploreInvozone = data?.strapiPage?.sections[8]
  const faqs = data?.strapiPage?.sections[9]

  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <InvozoneNumber />
      <FieldProven strapiData={fieldProven} />
      <Outsource strapiData={outsource} />
      {!isTablet && !isMobile ? (
        <Providing strapiData={provi} />
      ) : (
        <Providing2 strapiData={provi} />
      )}
      <OurApproach strapiData={approach} />
      <GotProject pageName="Application Development Services" />
      {!isTablet && !isMobile ? (
        <MeetInvozone strapiData={meetInvozone} />
      ) : (
        <MeetInvozone2 strapiData={meetInvozone} />
      )}
      <RefineProduct strapiData={refineProduct} />
      <EngagementModels strapiData={exploreInvozone} />
      {/* <TechnologiesUse /> */}
      {!isMobile && !isTablet ? (
        <AngularTechStacks
          heading={
            "Craft Game-Changing Experiences With The Technologies We Use"
          }
        />
      ) : (
        <AngularTechStacksMobile
          heading={
            "Craft Game-Changing Experiences With The Technologies We Use"
          }
        />
      )}
      <WorkPortfolio
        portfolioHead={"Our Recent"}
        portfolioDesc={"Build. Launch. Grow"}
      />
      <Testimonials />
      <Faqs strapiData={faqs} pageName="Application Development Services" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "application-development-services" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default applicationDevelopment
