import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import react1 from "../../images/application-development/react1.png"
import cir from "../../images/custom-web-application/cir.svg"
import Frame from "../../images/mobile-app-developmemt/Frame.svg"
import dots from "../../images/mobile-app-developmemt/Group.svg"
import * as styles from "./RefineProduct.module.scss"

const Achieve = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <div className={styles.dots1}>
        <img src={dots} alt="dots" decoding="async" loading="lazy" />
      </div>
      <div className={styles.cir}>
        <img src={cir} alt="circle" decoding="async" loading="lazy" />
      </div>
      <div className={styles.react1}>
        <img src={react1} alt="react" decoding="async" loading="lazy" />
      </div>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <div className="gap-30">
          <Row className={` ${styles.cardWrapper}`}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i < 3 && (
                    <Col lg={4} xs={12} key={i}>
                      <div className={styles.card}>
                        <img
                          src={e?.image1[0]?.localFile?.publicURL}
                          alt={e?.title}
                          decoding="async"
                          loading="lazy"
                        />
                        <h3>{e?.title}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: e?.subTitle,
                          }}
                        />
                      </div>
                    </Col>
                  )
              )}
          </Row>
          <Row className={`gap-30 pt-4 ${styles.cardWrapper}`}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i > 2 && (
                    <Col lg={6} xs={12} key={i} className="d-flex">
                      <div className={styles.card2}>
                        {e?.image1 && (
                          <img
                            src={e?.image1[0]?.localFile?.publicURL}
                            alt={e?.title}
                            decoding="async"
                            loading="lazy"
                          />
                        )}
                        <h3>{e?.title}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: e?.subTitle,
                          }}
                        />
                      </div>
                    </Col>
                  )
              )}
          </Row>
          <div className={styles.dots2}>
            <img src={dots} alt="dots" decoding="async" loading="lazy" />
          </div>
        </div>
        <div className={styles.teamBtn}>
          {strapiData?.buttons[0] && (
            <Link to={strapiData?.buttons[0]?.url} className="btn_black_border">
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
      <div className={styles.dots3}>
        <img src={Frame} alt="frame" decoding="async" loading="lazy" />
      </div>
    </div>
  )
}

export default Achieve
