// extracted by mini-css-extract-plugin
export var accImg = "OurApproach-module--accImg--23572";
export var approachImg = "OurApproach-module--approachImg--c2937";
export var approchDot1 = "OurApproach-module--approchDot1--8355c";
export var cir = "OurApproach-module--cir--de1a1";
export var description = "OurApproach-module--description--022a9";
export var dots1 = "OurApproach-module--dots1--8393b";
export var dots2 = "OurApproach-module--dots2--f3df2";
export var dots3 = "OurApproach-module--dots3--1b43e";
export var head = "OurApproach-module--head--c041a";
export var heading = "OurApproach-module--heading--411b0";
export var quesColor = "OurApproach-module--quesColor--5739f";
export var quesColorSelected = "OurApproach-module--quesColorSelected--956a3";
export var react1 = "OurApproach-module--react1--34306";
export var textColor = "OurApproach-module--textColor--8e89d";