// extracted by mini-css-extract-plugin
export var SliderWidth = "Providing-module--SliderWidth--7ed06";
export var arrowImg = "Providing-module--arrowImg--b58d0";
export var card = "Providing-module--card--9e030";
export var cardWrapper = "Providing-module--cardWrapper--44f85";
export var cards = "Providing-module--cards--79790";
export var description = "Providing-module--description--fa6e5";
export var fineBg = "Providing-module--fineBg--8e3bb";
export var heading = "Providing-module--heading--6ecdb";
export var headingPremium = "Providing-module--headingPremium--889d8";
export var premiumBg = "Providing-module--premiumBg--f4afe";
export var premiumImg = "Providing-module--premiumImg--2ffbd";
export var providingImg = "Providing-module--providingImg--1ed10";
export var teamButton = "Providing-module--teamButton--cf3ff";
export var teamWork = "Providing-module--teamWork--e1882";
export var trailBg = "Providing-module--trailBg--0c5f7";