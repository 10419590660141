import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./ApplicationDevelopment.scss"
import * as styles from "./MeetInvozone.module.scss"

const MeetInvozone = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(strapiData?.cards[0]?.title)

  const handleTabClick = label => {
    // console.log(label)
    setActiveTab(label)
  }

  return (
    <div className={styles.tabbingCards}>
      <div className="cardTabingApp">
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.description?.description,
            }}
          />
          <div className="Blocks">
            <Tab.Content className="contentBlocks3"></Tab.Content>
          </div>
          <div className="Blocks2">
            <Tab.Content className="contentBlocks2"></Tab.Content>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Designers">
            <div className={styles.cards}>
              <div className={styles.cardsContent}>
                <div>
                  <Nav variant="pills" className={`flex-column navbarBlock`}>
                    {strapiData?.cards &&
                      strapiData?.cards?.map(
                        (e, i) =>
                          i < 5 && (
                            <Nav.Item key={i}>
                              <div
                                className={`tabLink ${
                                  activeTab === e.title ? "active" : ""
                                }`}
                                onClick={() => handleTabClick(e.title)}
                              >
                                <div className={styles.tabData}>
                                  {e?.image1 && (
                                    <img
                                      src={e?.image1[0]?.localFile?.publicURL}
                                      alt={e?.title}
                                      loading="lazy"
                                      decoding="async"
                                    />
                                  )}
                                  {e?.title}
                                </div>
                              </div>
                            </Nav.Item>
                          )
                      )}
                  </Nav>
                </div>
                <div className="tabs">
                  <Tab.Content className="contentBlock">
                    {strapiData?.cards?.map((e, i) => (
                      <div
                        eventKey={`${e?.title}`}
                        key={i}
                        className={`tab-pane ${
                          activeTab === e.title ? "active" : ""
                        }`}
                      >
                        <div className={styles.boxContent}>
                          <div>
                            <img
                              loading="lazy"
                              decoding="async"
                              src={e?.image2[0]?.localFile?.publicURL}
                              alt={e?.title}
                            />
                          </div>
                          <h3>{e?.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          ></p>
                        </div>
                      </div>
                    ))}
                  </Tab.Content>
                </div>
              </div>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default MeetInvozone
