import React, { useEffect, useRef, useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import anakeed from "../../images/application-development/anakeed-mart.png"
import column from "../../images/application-development/column.png"
import ratingStars from "../../images/application-development/rating-stars.png"
import shieldRepublic from "../../images/application-development/shield-republic.png"
import stakester from "../../images/application-development/stakester.png"
import stitchHealth from "../../images/application-development/stitch-health.png"
import testLeft from "../../images/application-development/testLeft.png"
import testRight from "../../images/application-development/testRight.png"
import * as styles from "./Testimonials.module.scss"

const Testimonials = () => {
  const items = [
    {
      icon: shieldRepublic,
      text: "InvoZone is a great partner to work with. They took the time to understand the business and our priorities and came back with solid recommendations to create an interactive eCommerce website.",
      name: "CEO ShieldRepublic",
    },
    {
      icon: column,
      text: "I have finally found my ‘FOREVER’ Web development outsourcing company! Furqan and everyone at InvoZone have become an intricate part of our team and success.",
      name: "Owner Column",
    },
    {
      icon: anakeed,
      text: "It's always a pleasure to work with the InvoZone team, each member’s knowledge is remarkable and they are always available to guide you when you need help.",
      name: "Anakeed",
    },
    {
      icon: stitchHealth,
      text: "Loved working with the InvoZone team. They provide excellent eCommerce web design and development services. I highly recommend their services.",
      name: "StitchHealth",
    },
    {
      icon: stakester,
      text: "The people at InvoZone have proven they are experts at eCommerce web development and their work has produced great results for us.",
      name: "Stakester",
    },
  ]

  function Arrow(props) {
    const { onClick } = props
    return (
      <div className={styles.iconContainer}>
        <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
          <img
            src={testRight}
            alt="right arrow"
            className={styles.portfolioArrowIcon}
            decoding="async"
            loading="lazy"
          />
        </div>
      </div>
    )
  }
  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div className={styles.iconContainerLeft}>
        <div className={styles.portfolioArrowIconCover} onClick={onClick}>
          <img
            decoding="async"
            loading="lazy"
            src={testLeft}
            alt="left arrow"
            className={styles.portfolioArrowIcon}
            onClick={onClick}
          />{" "}
        </div>
      </div>
    )
  }

  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    current === items.length - 1 &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current])

  const settings = {
    dots: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    beforeChange: (current, next) => setCurrent(next),
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: false,
        },
      },
    ],
  }

  return (
    <section className={styles.section}>
      <Container>
        <div className="d-md-flex justify-content-between  ">
          <div>
            <h2>Cultivating Long-Term Relationships </h2>
          </div>

          <div className="pt-2 pt-md-0">
            <a
              href="https://clutch.co/profile/invozone"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="btn_black_border">
                Trusted By Top Companies
              </Button>
            </a>
          </div>
        </div>
        <Slider {...settings} ref={slide1}>
          {items?.map(({ icon, name, text }, i) => (
            <div key={i}>
              <Row className={styles.row}>
                <Col md={5} className="mr-3 mr-md-0">
                  <img
                    src={icon}
                    alt={name}
                    decoding="async"
                    loading="lazy"
                    className={styles.IconSlider}
                  />
                </Col>
                <Col md={7} className={styles.content}>
                  <div className="mb-3">
                    <img
                      className={styles.ratingStars}
                      src={ratingStars}
                      alt="rating stars"
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                  <div className="font-weight-normal font-italic mb-5">
                    {text}
                  </div>
                  <h3>{name}</h3>
                </Col>
              </Row>
            </div>
          ))}
        </Slider>
      </Container>
    </section>
  )
}

export default Testimonials
