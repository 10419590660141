import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import arrow from "../../images/application-development/arrow.svg"
import LeftArrow from "../../images/application-development/Left.svg"
import prioritizeimg from "../../images/application-development/prioritizeimg.svg"
import RightArrow from "../../images/application-development/right.svg"
import * as styles from "./Providing2.module.scss"

const Providing2 = ({ strapiData }) => {
  function Arrow(props) {
    const { onClick } = props
    return (
      <div className={styles.iconContainer}>
        <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
          <img
            decoding="async"
            loading="lazy"
            src={RightArrow}
            alt="right_arrow"
            onClick={onClick}
            className={styles.portfolioArrowIcon}
          />
        </div>
      </div>
    )
  }
  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div className={styles.iconContainerLeft}>
        <div className={styles.portfolioArrowIconCover} onClick={onClick}>
          <img
            decoding="async"
            loading="lazy"
            src={LeftArrow}
            alt="left_arrow"
            className={styles.portfolioArrowIcon}
          />
        </div>
      </div>
    )
  }

  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  useEffect(() => {
    current === strapiData[1]?.cards.length - 1 &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current])

  const settings = {
    autoplay: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    speed: 200,
    beforeChange: (current, next) => setCurrent(next),
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div className={styles.premiumBg}>
        <div className={styles.trailBg}>
          <Container>
            <Row>
              <Col lg={7} xs={{ span: 12, order: "last" }}>
                <div className={styles.headingPremium}>
                  <div className="headingYellow">
                    <h2
                      dangerouslySetInnerHTML={{ __html: strapiData[0]?.title }}
                    />
                  </div>
                  <div className={styles.teamButton}>
                    <Link
                      to={strapiData[0]?.buttons[0]?.url}
                      variant="light "
                      className="btn_white_border"
                    >
                      {strapiData[0]?.buttons[0]?.title}
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={5} xs={{ span: 12, order: "first" }}>
                <div className={styles.providingImg}>
                  <img
                    src={prioritizeimg}
                    alt={strapiData[0]?.secImages[0]?.alternativeText}
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.teamWork}>
          <Container>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData[1]?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData[1]?.description?.description,
              }}
            />
            <Row className={`gap-30 ${styles.cardWrapper}`}>
              <Slider {...settings} className={styles.SliderWidth} ref={slide1}>
                {strapiData[1]?.cards &&
                  strapiData[1]?.cards?.map((e, i) => (
                    <Col xs={12} key={i}>
                      <div className={styles.card}>
                        <div className={styles.fineBg}>
                          {e?.buttons[0]?.url && (
                            <Link to={e?.buttons[0]?.url}>
                              <img
                                className={styles.arrowImg}
                                src={arrow}
                                alt="arrow"
                                decoding="async"
                                loading="lazy"
                              />
                            </Link>
                          )}
                          <img
                            src={e?.image1[0]?.localFile?.publicURL}
                            alt={e?.title}
                            decoding="async"
                            loading="lazy"
                          />
                        </div>
                        <h3>{e?.title}</h3>
                        <p
                          className="mb-3"
                          dangerouslySetInnerHTML={{
                            __html: e?.subTitle,
                          }}
                        />
                      </div>
                    </Col>
                  ))}
              </Slider>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Providing2
