import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import arrow from "../../images/application-development/arrow.svg"
import prioritizeimg from "../../images/application-development/prioritizeimg.svg"
import * as styles from "./Providing.module.scss"
import "../fintech-landing-ar/Featured.scss"

const Providing = ({ strapiData }) => {
  const [current, setCurrent] = useState(0)
  const slide1 = useRef(null)
  const slide2 = useRef(null)

  useEffect(() => {
    slide1?.current?.slickGoTo(current)
    slide2?.current?.slickGoTo(current)
    current === 4 &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
        slide2?.current?.slickGoTo(0)
      }, 5000)
  }, [current])

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    infinite: false,
    dotsClass: "new-dot-app",
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 0,
    lazyLoad: "ondemand",
    beforeChange: (current, next) => setCurrent(next),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div className={styles.premiumBg}>
        <div className={styles.trailBg}>
          <Container>
            <Row>
              <Col lg={7}>
                <div className={styles.headingPremium}>
                  <div className="headingYellow">
                    <h2
                      dangerouslySetInnerHTML={{ __html: strapiData[0]?.title }}
                    />
                  </div>
                  <div className={styles.teamButton}>
                    <Link
                      to={strapiData[0]?.buttons[0]?.url}
                      variant="light "
                      className="btn_white_border"
                    >
                      {strapiData[0]?.buttons[0]?.title}
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className={styles.providingImg}>
                  <img
                    src={prioritizeimg}
                    alt={strapiData[0]?.secImages[0]?.alternativeText}
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.teamWork}>
          <Container>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData[1]?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData[1]?.description?.description,
              }}
            />
            <Row>
              <Col xl={6} lg={12} className={` ${styles.cardWrapper}`}>
                <Slider
                  {...settings}
                  ref={slide1}
                  className={styles.SliderWidth}
                >
                  {strapiData[1]?.cards &&
                    strapiData[1]?.cards?.map(
                      (e, i) =>
                        i < 5 && (
                          <Col
                            xs={12}
                            key={i}
                            onMouseEnter={() => {
                              slide2.current.slickPause(true)
                              slide1.current.slickPause(true)
                            }}
                            onMouseLeave={() => {
                              slide2.current.slickPlay(true)
                              slide1.current.slickPlay(true)
                            }}
                          >
                            <div className={`${styles.card} slidecard`}>
                              {e?.buttons[0]?.url && (
                                <Link to={e?.buttons[0]?.url}>
                                  <img
                                    className={styles.arrowImg}
                                    src={arrow}
                                    alt="arrow"
                                    decoding="async"
                                    loading="lazy"
                                  />
                                </Link>
                              )}
                              <div className={styles.fineBg}>
                                <img
                                  src={e?.image1[0]?.localFile?.publicURL}
                                  alt={e?.title}
                                  decoding="async"
                                  loading="lazy"
                                />
                              </div>
                              <h3>{e?.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              />
                            </div>
                          </Col>
                        )
                    )}
                </Slider>
              </Col>
              <Col xl={6} lg={12} className={`${styles.cardWrapper}`}>
                <Slider
                  {...{ ...settings, dots: false }}
                  ref={slide2}
                  className={styles.SliderWidth}
                >
                  {strapiData[1]?.cards &&
                    strapiData[1]?.cards?.map(
                      (e, i) =>
                        i > 4 && (
                          <Col
                            xs={12}
                            key={i}
                            onMouseEnter={() => {
                              slide2.current.slickPause(true)
                              slide1.current.slickPause(true)
                            }}
                            onMouseLeave={() => {
                              slide2.current.slickPlay(true)
                              slide1.current.slickPlay(true)
                            }}
                          >
                            <div className={`${styles.card} slidecard`}>
                              {e?.buttons[0]?.url && (
                                <Link to={e?.buttons[0]?.url}>
                                  <img
                                    className={styles.arrowImg}
                                    src={arrow}
                                    alt="arrow"
                                    decoding="async"
                                    loading="lazy"
                                  />
                                </Link>
                              )}
                              <div className={styles.fineBg}>
                                <img
                                  src={e?.image1[0]?.localFile?.publicURL}
                                  alt={e?.title}
                                  decoding="async"
                                  loading="lazy"
                                />
                              </div>
                              <h3>{e?.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              />
                            </div>
                          </Col>
                        )
                    )}
                </Slider>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Providing
