// extracted by mini-css-extract-plugin
export var SliderWidth = "Providing2-module--SliderWidth--2d8b0";
export var arrowImg = "Providing2-module--arrowImg--5801f";
export var card = "Providing2-module--card--f5325";
export var cardWrapper = "Providing2-module--cardWrapper--07bad";
export var cards = "Providing2-module--cards--c02de";
export var description = "Providing2-module--description--a0337";
export var fineBg = "Providing2-module--fineBg--97d04";
export var heading = "Providing2-module--heading--ff4e8";
export var headingPremium = "Providing2-module--headingPremium--1eb2f";
export var iconContainer = "Providing2-module--iconContainer--1928e";
export var iconContainerLeft = "Providing2-module--iconContainerLeft--ce54c";
export var portfolioArrowIcon = "Providing2-module--portfolioArrowIcon--b5ca4";
export var portfolioArrowIconCover = "Providing2-module--portfolioArrowIconCover--28262";
export var portfolioArrowRightIconCover = "Providing2-module--portfolioArrowRightIconCover--eae16";
export var premiumBg = "Providing2-module--premiumBg--0d25b";
export var premiumImg = "Providing2-module--premiumImg--ff92a";
export var providingImg = "Providing2-module--providingImg--b4af7";
export var teamButton = "Providing2-module--teamButton--3dda3";
export var teamWork = "Providing2-module--teamWork--b6bba";
export var trailBg = "Providing2-module--trailBg--f5a18";