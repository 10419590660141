// extracted by mini-css-extract-plugin
export var IconSlider = "Testimonials-module--IconSlider--a9c9c";
export var content = "Testimonials-module--content--78821";
export var iconContainer = "Testimonials-module--iconContainer--753af";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--0b0b8";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--e3e9c";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--b0754";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--ae651";
export var ratingStars = "Testimonials-module--ratingStars--63cae";
export var row = "Testimonials-module--row--824cd";
export var section = "Testimonials-module--section--27338";
export var testimonials = "Testimonials-module--testimonials--0ae7c";