// extracted by mini-css-extract-plugin
export var card = "RefineProduct-module--card--54359";
export var card2 = "RefineProduct-module--card2--29622";
export var cardFlex = "RefineProduct-module--cardFlex--8d4db";
export var cardWrapper = "RefineProduct-module--cardWrapper--ce796";
export var cir = "RefineProduct-module--cir--3df1e";
export var description = "RefineProduct-module--description--f75ff";
export var dots1 = "RefineProduct-module--dots1--29b88";
export var dots2 = "RefineProduct-module--dots2--c73ef";
export var dots3 = "RefineProduct-module--dots3--e3099";
export var heading = "RefineProduct-module--heading--42dfc";
export var react1 = "RefineProduct-module--react1--c1da3";
export var subservice = "RefineProduct-module--subservice--20fbf";
export var teamBtn = "RefineProduct-module--teamBtn--8ecec";