// extracted by mini-css-extract-plugin
export var arrowImg = "FieldProven-module--arrowImg--571e3";
export var card = "FieldProven-module--card--27029";
export var cardWrapper = "FieldProven-module--cardWrapper--8fc78";
export var choseImg = "FieldProven-module--choseImg--2180e";
export var cir = "FieldProven-module--cir--c80d7";
export var description = "FieldProven-module--description--9f401";
export var dots1 = "FieldProven-module--dots1--5c2a3";
export var dots2 = "FieldProven-module--dots2--f1cae";
export var dots3 = "FieldProven-module--dots3--0c6d5";
export var dots4 = "FieldProven-module--dots4--e07e1";
export var heading = "FieldProven-module--heading--a7a8c";
export var react1 = "FieldProven-module--react1--65794";
export var subservice = "FieldProven-module--subservice--5c5e1";
export var teamBtn = "FieldProven-module--teamBtn--4f372";