import React, { useContext } from "react"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import up from "../../images/android-App-Development/upp.png"
import down from "../../images/android-App-Development/down.png"
import cir from "../../images/custom-web-application/cir.svg"
import Frame from "../../images/mobile-app-developmemt/Frame.svg"
import dots from "../../images/mobile-app-developmemt/Group.svg"
import * as styles from "./MeetInvozone2.module.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
        <div type="button">
          {isCurrentEventKey ? (
            <img
              src={up}
              decoding="async"
              loading="lazy"
              className={styles.quesColorSelected}
              alt="icon"
            />
          ) : (
            <img
              src={down}
              decoding="async"
              loading="lazy"
              className={styles.quesColor}
              alt="icon"
            />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const Faqs = ({ strapiData }) => {
  return (
    <section className={styles.meetInvozone2}>
      <div className={styles.cir} loading="lazy" decoding="async">
        <img src={cir} alt="circle" loading="lazy" decoding="async" />
      </div>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <div className={styles.dots1}>
          <img src={dots} alt="dots" loading="lazy" decoding="async" />
        </div>
        <Row>
          <Col>
            <Row>
              <Col lg={12}>
                <div className="approachAcc">
                  <Accordion defaultActiveKey={`ek-0`}>
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Card key={i} border="light">
                          <ContextAwareToggle eventKey={`ek-${i}`}>
                            <div className={styles.accImg}>
                              {e?.image1 && (
                                <img
                                  loading="lazy"
                                  decoding="async"
                                  src={e?.image1[0]?.localFile?.publicURL}
                                  alt={e?.title}
                                />
                              )}
                              <h3 className="mb-0 py-2" type="button">
                                {e?.title}
                              </h3>
                            </div>
                          </ContextAwareToggle>
                          <Accordion.Collapse eventKey={`ek-${i}`}>
                            <Card.Body className={styles.textColor}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                          <hr style={{ margin: 0 }} />
                        </Card>
                      ))}
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className={styles.dots2}>
        <img src={dots} alt="dots" loading="lazy" decoding="async" />
      </div>
      <div className={styles.dots3}>
        <img src={Frame} alt="frame" loading="lazy" decoding="async" />
      </div>
    </section>
  )
}

export default Faqs
